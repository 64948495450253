import { Button } from 'shared/ui/Button';
import { FC, useCallback, useState } from 'react';
import { useCustomizeBoxSend } from '../../services/emails';

// useCustomizeBoxSend()
export const CustomizeBoxButton: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const send = useCustomizeBoxSend();

  const onClick = async () => {
    setIsLoading(true);
    try {
      const {
        data: { sendCustomizeBox },
      } = await send();
      alert(sendCustomizeBox);
    } catch (error) {
      alert(error);
    }

    setIsLoading(false);
  };

  return (
    <Button
      variant="contained"
      size="regular"
      onClick={onClick}
      disabled={isLoading}
    >
      Send customize box emails
    </Button>
  );
};
