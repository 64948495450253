import TextField from '@material-ui/core/TextField';
import { Button } from 'shared/ui/Button';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SearchInput } from '../grid/SearchInput';

export const SearchForm = () => {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <SearchInput
      autoFocus
      className="w-32"
      placeholder="Quick find..."
      value={searchQuery}
      onChange={(x) => setSearchQuery(x.target.value)}
      rightSide={
        <>
          <Button
            type="button"
            size="regular"
            variant="contained"
            color="primary"
            className="sm:px-4"
            onClick={() =>
              router.push(`/orders?q=${encodeURIComponent(searchQuery)}`)
            }
          >
            Find orders
          </Button>
          <Button
            type="button"
            size="regular"
            variant="contained"
            color="primary"
            className="sm:px-4"
            onClick={() =>
              router.push(`/users?q=${encodeURIComponent(searchQuery)}`)
            }
          >
            Find users
          </Button>
        </>
      }
    />
  );
};
