import clsx from 'clsx';
import RouterLink from 'next/link';

const colorValues = {
  primary: 'text-orange-600 underline cursor-pointer',
  secondary: 'text-gray-900 underline cursor-pointer',
  header: 'text-gray-900 hover:text-orange-600 transition cursor-pointer',
  custom: 'cursor-pointer',
};

export function Link({
  className,
  children,
  external,
  color = 'custom',
  ...props
}) {
  const linkCls = clsx(colorValues[color], className);
  const { href } = props;

  if (href) {
    if (external || href.startsWith('http')) {
      props.target = '_blank';
      props.rel = 'nofollow noopener';
    }

    if (
      href.startsWith('http') ||
      href.startsWith('tel:') ||
      href.startsWith('mailto:')
    ) {
      return (
        <a className={linkCls} href={href} {...props}>
          {children}
        </a>
      );
    }
  } else {
    return (
      <a className={linkCls} {...props}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink {...props}>
      <a className={linkCls}>{children}</a>
    </RouterLink>
  );
}
