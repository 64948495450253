import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import merge from 'deepmerge';
import { useMemo } from 'react';
import { getToken } from './AuthContext';

export let apolloClient: ApolloClient<InMemoryCache>;

// const logoutLink = onError(
//   ({
//     graphQLErrors,
//     networkError,
//     response,
//     operation,
//     forward,
//   }: ErrorResponse) => {
//     // if (!graphQLErrors) {
//     //   return;
//     // }
//     // for (const error of graphQLErrors) {
//     //   if (
//     //     error.message === 'Not Authorised!' ||
//     //     error?.extensions?.code === 'UNAUTHENTICATED'
//     //   ) {
//     //     //logout();
//     //     apolloClient.resetStore();
//     //     removeAuthToken();
//     //   }
//     // }
//     // @ts-ignore
//     // if (networkError?.statusCode === 401) debugger; //logout();
//   }
// );

// const authLink = setContext((_, { headers }) => {
//   const token = getAuthToken();

//   if (!token) {
//     return { headers };
//   }

//   return {
//     headers: {
//       ...headers,
//       authorization: `Bearer ${token}`,
//     },
//   };
// });

function createApolloClient(req?: any) {
  let headers;
  const token = getToken(req);
  if (token) {
    headers = {
      // ...headers,
      Authorization: `Bearer ${token}`,
    };
    // headers = { cookie: req.headers.cookie };
  }

  const uri = `${process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL}/gql`;
  const cache = new InMemoryCache();

  const uploadLink = createUploadLink({
    uri,
    // credentials: 'include',
    headers,
  });
  // https://www.apollographql.com/docs/react/networking/authentication/
  // TODO https://www.apollographql.com/docs/react/networking/advanced-http-networking/
  // const httpLink = new HttpLink({
  //   uri,
  //   // credentials: 'include',
  //   headers,
  // });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    // link: logoutLink.concat(httpLink),
    // @ts-ignore
    link: uploadLink,
    // link: ApolloLink.from([uploadLink, httpLink]),
    // link: logoutLink.concat(authLink.concat(httpLink)),
    cache,
  });
}

export function initializeApollo(initialState?: any, req?: any) {
  const _apolloClient = apolloClient ?? createApolloClient(req);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    // @ts-ignore
    const data = merge(initialState, existingCache);

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    // @ts-ignore
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
// export function initializeApollo(initialState = null) {
//   const _apolloClient = apolloClient ?? createApolloClient()

//   // If your page has Next.js data fetching methods that use Apollo Client, the initial state
//   // gets hydrated here
//   if (initialState) {
//     _apolloClient.cache.restore(initialState)
//   }
//   // For SSG and SSR always create a new Apollo Client
//   if (typeof window === 'undefined') return _apolloClient
//   // Create the Apollo Client once in the client
//   if (!apolloClient) apolloClient = _apolloClient

//   return _apolloClient
// }

export function useApollo(initialState?: any) {
  // export function useApollo(pageProps) {
  //   const state = pageProps[APOLLO_STATE_PROP_NAME]
  //   const store = useMemo(() => initializeApollo(state), [state])
  //   return store
  // }
  return useMemo(() => initializeApollo(initialState), [initialState]);
}

// import { concatPagination } from '@apollo/client/utilities'
// import merge from 'deepmerge'
// import isEqual from 'lodash/isEqual'

// export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

// let apolloClient

// function createApolloClient() {
//   return new ApolloClient({
//     ssrMode: typeof window === 'undefined',
//     link: new HttpLink({
//       uri: 'https://nextjs-graphql-with-prisma-simple.vercel.app/api', // Server URL (must be absolute)
//       credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
//     }),
//     cache: new InMemoryCache({
//       typePolicies: {
//         Query: {
//           fields: {
//             allPosts: concatPagination(),
//           },
//         },
//       },
//     }),
//   })
// }

// export function initializeApollo(initialState = null) {
//   const _apolloClient = apolloClient ?? createApolloClient()

//   // If your page has Next.js data fetching methods that use Apollo Client, the initial state
//   // gets hydrated here
//   if (initialState) {
//     // Get existing cache, loaded during client side data fetching
//     const existingCache = _apolloClient.extract()

//     // Merge the existing cache into data passed from getStaticProps/getServerSideProps
//     const data = merge(initialState, existingCache, {
//       // combine arrays using object equality (like in sets)
//       arrayMerge: (destinationArray, sourceArray) => [
//         ...sourceArray,
//         ...destinationArray.filter((d) =>
//           sourceArray.every((s) => !isEqual(d, s))
//         ),
//       ],
//     })

//     // Restore the cache with the merged data
//     _apolloClient.cache.restore(data)
//   }
//   // For SSG and SSR always create a new Apollo Client
//   if (typeof window === 'undefined') return _apolloClient
//   // Create the Apollo Client once in the client
//   if (!apolloClient) apolloClient = _apolloClient

//   return _apolloClient
// }

// export function addApolloState(client, pageProps) {
//   if (pageProps?.props) {
//     pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
//   }

//   return pageProps
// }

// import {
//   ApolloClient,
//   HttpLink,
//   ApolloLink,
//   InMemoryCache,
//   concat,
// } from '@apollo/client';

// const httpLink = new HttpLink({ uri: '/graphql' });

// const authMiddleware = new ApolloLink((operation, forward) => {
//   // add the authorization to the headers
//   operation.setContext(({ headers = {} }) => ({
//     headers: {
//       ...headers,
//       authorization: localStorage.getItem('token') || null,
//     },
//   }));

//   return forward(operation);
// });

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: concat(authMiddleware, httpLink),
// });

// import {
//   ApolloClient,
//   HttpLink,
//   ApolloLink,
//   InMemoryCache,
//   from,
// } from '@apollo/client';

// const httpLink = new HttpLink({ uri: '/graphql' });

// const authMiddleware = new ApolloLink((operation, forward) => {
//   // add the authorization to the headers
//   operation.setContext(({ headers = {} }) => ({
//     headers: {
//       ...headers,
//       authorization: localStorage.getItem('token') || null,
//     },
//   }));

//   return forward(operation);
// });

// const activityMiddleware = new ApolloLink((operation, forward) => {
//   // add the recent-activity custom header to the headers
//   operation.setContext(({ headers = {} }) => ({
//     headers: {
//       ...headers,
//       'recent-activity': localStorage.getItem('lastOnlineTime') || null,
//     },
//   }));

//   return forward(operation);
// });

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: from([authMiddleware, activityMiddleware, httpLink]),
// });

// import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
// import { onError } from '@apollo/client/link/error';

// import { logout } from './logout';

// const httpLink = new HttpLink({ uri: '/graphql' });

// const logoutLink = onError(({ networkError }) => {
//   if (networkError.statusCode === 401) logout();
// });

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: logoutLink.concat(httpLink),
// });
