import clsx from 'clsx';
import { forwardRef } from 'react';

export const Textarea = forwardRef(
  (
    { name, placeholder, label, rows = 3, className, isInvalid, ...props },
    ref
  ) => {
    return (
      <div className="floating-input relative">
        <textarea
          ref={ref}
          id={name}
          name={name}
          rows={rows}
          placeholder={placeholder || ' '}
          className={clsx(
            'block w-full px-3 py-3 shadow-sm rounded-lg border-2 border-gray-300 focus:ring-gray-500 focus:border-gray-500 transition',
            isInvalid && 'border-red-500',
            className
          )}
          {...props}
        />
        <label
          htmlFor={name}
          className="text-gray-600 absolute top-0 left-0 px-3 py-4 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out"
        >
          {label}
        </label>
      </div>
    );
  }
);
