import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { AuthProvider } from 'shared/auth/AuthContext';
import { useApollo } from 'shared/auth/apollo';
import Head from 'next/head';
import { useEffect } from 'react';
import { Header } from '../components/ui/Header';
import '../styles/tailwind.css';
import theme from '../styles/theme';

function MyApp({ Component, pageProps, err }) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        {/* Base */}
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        {/* Use minimum-scale=1 to enable GPU rasterization */}
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          // content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no"
        />
        <meta
          name="format-detection"
          content="telephone=no, address=no, email=no"
        />

        {/* SEO */}
        <meta name="og:image" content="/assets/og-image.jpeg" />

        {/* Fonts */}
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />

        {/* Icons */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Head>

      <ApolloProvider client={apolloClient}>
        <AuthProvider initialIdentity={pageProps.initialIdentity}>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <div className="min-h-screen flex flex-col">
              <Header />

              <div className="flex-1 p-4">
                {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
                <Component {...pageProps} err={err} />
              </div>
            </div>
          </ThemeProvider>
        </AuthProvider>
      </ApolloProvider>
    </>
  );
}

export default MyApp;
