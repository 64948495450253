import clsx from 'clsx';
import { FC } from 'react';

interface Props {}

export const SearchInput: FC<Props> = ({
  rightSide,
  placeholder,
  className,
  ...restProps
}) => {
  return (
    <div className="flex space-x-2 sm:space-x-2">
      <input
        type="text"
        className={clsx(
          'px-3 py-2 bg-gray-200 border-0 border-gray-200 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 rounded-lg',
          className
        )}
        placeholder={placeholder || 'Quick search... (⌘K)'}
        {...restProps}
      />
      {rightSide ? rightSide : null}
    </div>
  );
};
