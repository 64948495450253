import { gql, useMutation } from '@apollo/client';

const SEND_EMAILS_MUTATION = gql`
  mutation emailsSend($shippingDate: String!, $type: String!) {
    emailsSend(shippingDate: $shippingDate, type: $type)
  }
`;

const SEND_THANK_YOU_EMAIL_MUTATION = gql`
  mutation emailThankYou($orderId: String!) {
    emailThankYou(orderId: $orderId)
  }
`;

const SEND_CUSTOMIZE_BOX_EMAIL_MUTATION = gql`
  mutation sendCustomizeBox($fulfillmentDate: String) {
    sendCustomizeBox(fulfillmentDate: $fulfillmentDate)
  }
`;

export function useEmailsSend() {
  const [mutate] = useMutation(SEND_EMAILS_MUTATION);
  return async function (shippingDate: string, type: string) {
    return await mutate({
      variables: {
        shippingDate,
        type,
      },
    });
  };
}

export function useThankYouSend() {
  const [mutate] = useMutation(SEND_THANK_YOU_EMAIL_MUTATION);
  return async function (orderId: string) {
    return await mutate({
      variables: {
        orderId,
      },
    });
  };
}

export function useCustomizeBoxSend() {
  const [mutate] = useMutation(SEND_CUSTOMIZE_BOX_EMAIL_MUTATION);
  return async function (fulfillmentDate?: string) {
    return await mutate({
      variables: {
        fulfillmentDate,
      },
    });
  };
}
