import MuiDialog from '@material-ui/core/Dialog';
import React, { memo } from 'react';
import { DialogTitle } from './DialogTitle';

export const Dialog = memo(({ title, onClose, children, ...props }) => {
  // const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <MuiDialog
      // fullScreen={smDown}
      onClose={onClose}
      {...props}
    >
      <DialogTitle title={title} onClose={onClose} />
      {children}
    </MuiDialog>
  );
});

Dialog.defaultProps = {
  maxWidth: 'sm',
  fullWidth: true,
  fullScreen: false,
};
