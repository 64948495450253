import clsx from 'clsx';
import { Link } from './Link';
import { Spinner } from './Spinner';

const sizeValues = {
  small: `p-1 text-sm font-medium rounded-md`,
  regular: `p-2 text-base font-medium rounded-md`,
  large: `h-14 px-8 text-base font-extrabold rounded-lg`,
};

const outlinedColorValues = {
  primary: 'border-gray-900 text-gray-900 bg-orange-500 hover:bg-orange-300',
  secondary: 'border-gray-900 text-white bg-green-500 hover:bg-green-300',
};

const containedColorValues = {
  primary: 'text-orange-900 bg-orange-100 hover:bg-orange-200',
  primaryLight: 'text-white bg-orange-500 hover:bg-orange-300',
  secondary: 'text-green-900 bg-green-100 hover:bg-green-200',
  secondaryLight: 'text-green-500 bg-green-100 hover:bg-green-200',
  white: 'text-gray-900 bg-white hover:bg-gray-50',
  facebook: 'text-white bg-[#1877F2] hover:opacity-90',
  danger:
    'text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-white',
};

const textColorValues = {
  primary: 'text-orange-900 hover:bg-orange-100',
  secondary: 'text-green-900  hover:bg-green-100',
};

export function Button({
  color = 'primary',
  size = 'large',
  variant = 'outlined',
  loading,
  loadingText = 'Przetwarzanie...',
  className,
  disabled,
  href,
  external,
  fullWidth,
  children,
  ...props
}) {
  const sizeCls = sizeValues[size];

  const basicCls = clsx(
    `relative inline-flex items-center justify-center transition focus:outline-none`,
    sizeCls,
    disabled && 'cursor-not-allowed',
    fullWidth && 'w-full',
    className
  );

  const content = loading ? (
    <>
      <Spinner size={size} className="mr-2" />
      {loadingText}
    </>
  ) : (
    children
  );

  let btnCls = '';
  switch (variant) {
    case 'outlined':
      btnCls = clsx(
        `border-2`,
        (disabled || loading) && 'border-gray-400 text-gray-400 bg-gray-100',
        !disabled && !loading && outlinedColorValues[color]
      );

      break;

    case 'contained':
      btnCls = clsx(
        (disabled || loading) && 'border-gray-200 text-gray-400 bg-gray-200',
        !disabled && !loading && containedColorValues[color]
      );

      break;

    // TextButton
    default:
      btnCls = clsx(
        (disabled || loading) && 'text-gray-400',
        !disabled && !loading && textColorValues[color]
      );

      break;
  }

  if (href) {
    if (external || href.startsWith('http')) {
      props.target = '_blank';
      props.rel = 'nofollow noopener';
    }

    if (
      href.startsWith('http') ||
      href.startsWith('tel:') ||
      href.startsWith('mailto:')
    ) {
      return (
        <a className={clsx(btnCls, basicCls)} href={href} {...props}>
          {content}
        </a>
      );
    }

    return (
      <Link href={href} className={clsx(btnCls, basicCls)} {...props}>
        {content}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled || loading}
      className={clsx(basicCls, btnCls)}
      {...props}
    >
      {content}
    </button>
  );
}
