import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';

export const DialogTitle = ({ title, onClose }) => {
  return (
    <MuiDialogTitle>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        {onClose && (
          <Grid item>
            <IconButton edge="end" aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </MuiDialogTitle>
  );
};
