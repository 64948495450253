import MuiDialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export const DialogContent = memo(({ children, ...props }) => {
  const c = useStyles();

  return (
    <MuiDialogContent className={c.content} {...props}>
      {children}
    </MuiDialogContent>
  );
});
