import { useMediaQuery } from '@material-ui/core';
import { Button } from 'shared/ui/Button';
import { FC, useEffect, useState } from 'react';
import { Dialog } from '../Dialog';
import { DialogContent } from '../DialogContent';
import { QuickFindContent } from './QuickFindContent';
import { useEventListener } from 'shared/hooks/useEventListener';
import { canUseDOM, canUseEventListeners } from 'shared/base/dom';
import { useRouter } from 'next/router';

export const QuickFindModal: FC = () => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleUserKeyPress = (e) => {
    console.log(e.key);
    if ((e.key === 'k' && e.ctrlKey) || (e.key === 'k' && e.metaKey)) {
      setOpen(true);
    }
    return;
  };

  useEventListener('keydown', handleUserKeyPress);

  useEffect(() => {
    open && setOpen(false);
  }, [router]);

  return (
    <div onKeyDown={handleUserKeyPress}>
      <Button variant="contained" size="regular" onClick={() => setOpen(true)}>
        Quick Find{smDown ? '' : ' (⌘K)'}
      </Button>
      <Dialog
        fullScreen={smDown}
        open={open}
        onClose={() => setOpen(false)}
        title="Quick Find"
      >
        <DialogContent>
          <QuickFindContent />
        </DialogContent>
      </Dialog>
    </div>
  );
};
