import { useMediaQuery } from '@material-ui/core';
import { Textarea } from 'shared/forms/Textarea';
import { Button } from 'shared/ui/Button';
import { FC, useCallback, useState } from 'react';
import { Dialog } from '../Dialog';
import { DialogContent } from '../DialogContent';
import { useMutation, gql, useQuery } from '@apollo/client';
import { Link } from 'shared/ui/Link';

export const ImportBoxVariantModal: FC = () => {
  const [open, setOpen] = useState(false);
  const [json, setJson] = useState(`{
    "startsAt": "2021-12-11",
    "endsAt": "2021-12-19",
    "smallGroups": [6, 5, 2, 1],
    "bigGroups": [6, 6, 5, 3],
    "ingredients": []
  }`);
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [boxVariantImport] = useMutation(gql`
    mutation boxVariantImport($json: String!) {
      boxVariantImport(json: $json)
    }
  `);

  const onImport = useCallback(async () => {
    if (!window.confirm(`Czy napewno chcesz zaimportować?`)) {
      return;
    }
    try {
      await boxVariantImport({
        variables: {
          json,
        },
      });
      setOpen(false);
      alert('Imported');
    } catch (error) {
      alert(error.message);
    }
  }, [boxVariantImport, json]);

  return (
    <>
      <Button variant="contained" size="regular" onClick={() => setOpen(true)}>
        Import box variant
      </Button>
      <Dialog
        fullScreen={smDown}
        open={open}
        onClose={() => setOpen(false)}
        title="Import box variant"
      >
        <DialogContent>
          <div className="grid gap-4">
            <ol>
              <li>
                <Link
                  external
                  className="text-orange-400"
                  href="https://docs.google.com/spreadsheets/d/1utFL8RiH7ABxNR2s5uJK8GX43J9V3ET5qZ38QejbrOc/edit#gid=647887933"
                >
                  Sheet with actual box variant
                </Link>
              </li>
              <li>
                <Link
                  external
                  className="text-orange-400"
                  href="https://www.convertonline.io/convert/js-to-json"
                >
                  Convert JS to JSON
                </Link>
              </li>
            </ol>
            <Textarea
              rows={15}
              value={json}
              onChange={(e) => setJson(e.target.value)}
            />
            <Button variant="contained" size="regular" onClick={onImport}>
              Import
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
