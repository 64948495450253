import { useAuth, useIdentity } from 'shared/auth/AuthContext';
import { Button } from 'shared/ui/Button';
import { Link } from 'shared/ui/Link';
import { QuickFindModal } from '../../components/QuickFind/QuickFindModal';

function AuthInfo() {
  const session = useIdentity();
  const { signOut } = useAuth();

  if (session) {
    return (
      <div>
        <span className="mr-2">{session.email}</span>
        <Button onClick={signOut} variant="contained" size="regular">
          Sign Out
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Button href="/signin" variant="contained" size="regular">
        Sign In
      </Button>
    </div>
  );
}

export function Header() {
  const session = useIdentity();

  if (!session) {
    return (
      <div className="m-4">
        <AuthInfo />
      </div>
    );
  }

  return (
    <div className="grid sm:grid-cols-3 items-center gap-6 p-4 border-b-2">
      <div className="flex flex-row space-x-4 items-center">
        <QuickFindModal />
        <Link href="/deliveries" className="text-indigo-500 underline">
          Deliveries
        </Link>
      </div>
      <strong className="hidden sm:flex flex-col items-center text-2xl">
        DIANA
      </strong>
      <div className="hidden sm:flex flex-col items-end">
        <AuthInfo />
      </div>
    </div>
  );
}
