import { gql, useMutation } from '@apollo/client';
import { useApollo } from './apollo';
import Cookies from 'js-cookie';
import { NextRequest } from 'next/server';
import { configureScope, setUser, captureException } from '@sentry/nextjs';
import {
  createContext,
  createElement,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Router from 'next/router';

export type IdentityType = {
  id: string;
  role: string;
  referralCouponCode: string;
  email?: string;
  isEmailVerified: boolean;
  phone?: string;
  isPhoneVerified: boolean;
  firstName?: string;
  lastName?: string;
  gender?: string;
  dateOfBirth?: string;
  householdMembers?: string;
  producePreference?: string;
  diet?: string;
};

type AuthContextType = {
  identity: IdentityType | null;
  loading: boolean;
  signIn: (token: string) => void;
  signOut: () => void;
  refetchIdentity: () => void;
};

export async function getLongLivedToken(provider: string, searchParams: any) {
  const params = new URLSearchParams(searchParams);
  try {
    const authResponse = await fetch(
      `${
        process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL
      }/auth/${provider}/token?${params.toString()}`
    );
    const data = await authResponse.json();
    return data?.token;
  } catch (e) {
    captureException(e);
    return null;
  }
}

export function setToken(longLivedToken: string) {
  const isLocal =
    process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL === 'http://localhost:4000';
  Cookies.set('auth-token', longLivedToken, {
    path: '/',
    expires: 7, // 7 days
    secure: !isLocal,
    // httpOnly: !isLocal,
    sameSite: 'strict',
    domain: isLocal ? undefined : '.wojnawarzyw.pl',
  });
}

export function getToken(req?: NextRequest) {
  return process.browser
    ? Cookies.get('auth-token')
    : req?.cookies['auth-token'];
}

export async function getIdentity(req?: NextRequest) {
  try {
    const token = getToken(req);
    if (!token) {
      return null;
    }

    const authResponse = await fetch(
      `${process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL}/auth/identity`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return await authResponse.json();
  } catch (e) {
    captureException(e);
    return null;
  }
}

export async function clearAllCache() {
  Cookies.remove('auth-token');
  // clear cart?
}

// @ts-ignore
const AuthContext = createContext<AuthContextType>();
// ({
//   identity: null,
//   loading: false,
//   signIn: (token: string) => null,
//   signOut: () => null,
//   refetchIdentity: () => null,
// });

export function useAuth(initialIdentity?: IdentityType) {
  const context = useContext(AuthContext);
  if (context) return context;
  return _useAuthHook(initialIdentity || null);
}

export function useIdentity() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('Please use within AuthProvider');
  }
  return useMemo(() => context.identity, [context.identity]);
}

function _useAuthHook(initialIdentity: IdentityType | null) {
  const [data, setData] = useState<IdentityType | null>(initialIdentity);
  const [loading, setLoading] = useState(!data);
  const apolloClient = useApollo();
  const [logoutOnServer] = useMutation(
    gql`
      mutation {
        logout
      }
    `
  );

  const refetchIdentity = useCallback(async () => {
    setLoading(true);
    try {
      const identity = await getIdentity();
      setData(identity);
    } catch (error) {
      captureException(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    refetchIdentity();
  }, [refetchIdentity]);

  const signIn = useCallback(async (longLivedToken: string) => {
    try {
      setToken(longLivedToken);
      const identity = await getIdentity();
      setData(identity);
      Router.push('/');
      setUser({
        id: identity.id,
        email: identity?.email,
        username: identity?.phone,
      });
    } catch (err) {
      captureException(err);
      clearAllCache();
      alert('Coś poszło nie tak podczas autoryzacji 😭');
    }
  }, []);

  const signOut = useCallback(async () => {
    try {
      await logoutOnServer();
    } catch (e) {
      captureException(e);
    }
    await clearAllCache();
    await apolloClient.resetStore();
    setData(null);
    configureScope((scope) => scope.setUser(null));
    Router.push('/signin');
  }, [apolloClient, logoutOnServer]);

  return { identity: data, loading, signIn, signOut, refetchIdentity };
}

export const AuthProvider: FC<{ initialIdentity?: IdentityType }> = ({
  children,
  initialIdentity,
}) => {
  return createElement(
    AuthContext.Provider,
    { value: useAuth(initialIdentity) },
    children
  );
};

// export async function getIdentity() {
//   try {
//     const res = await apolloClient.query({
//       query: gql`
//         query {
//           me {
//             id
//             role
//             referralCouponCode
//             email
//             isEmailVerified
//             phone
//             isPhoneVerified
//             firstName
//             lastName
//             gender
//             dateOfBirth
//             householdMembers
//             producePreference
//             diet
//           }
//         }
//       `,
//       fetchPolicy: 'no-cache',
//     });
//     return res?.data?.me;
//   } catch (e) {
//     captureException(e);
//     return null;
//   }
// }

// export async function signIn(longLivedToken: string) {
//   try {
//     await setToken(longLivedToken);

//     const session = await getSession();

//     // dispatch({ type: SIGN_IN, identity });
//     setUser({
//       id: session.id,
//       email: session?.email,
//       username: session?.phone,
//     });
//     Router.push('/');
//   } catch (err) {
//     captureException(err);
//     clearAllCache();
//     alert('Coś poszło nie tak podczas autoryzacji 😭');
//   }
// }

// export async function signOut() {
//   await apolloClient.mutate({
//     mutation: gql`
//       mutation {
//         logout
//       }
//     `,
//   });
//   await clearAllCache();
//   await apolloClient.resetStore();
//   // dispatch({ type: SIGN_OUT });
//   configureScope((scope) => scope.setUser(null));
//   Router.push('/signin');
// }
