import { FC } from 'react';
import { CustomizeBoxButton } from './CustomizeBoxButton';
import { ImportBoxVariantModal } from './ImportBoxVariantModal';
import { SearchForm } from '../orders/SearchForm';
import { Link } from 'shared/ui/Link';

export const QuickFindContent: FC = () => {
  return (
    <div className="grid grid-cols-2 sm:gap-4 gap-2">
      <div className="col-span-2">
        <SearchForm />
      </div>
      <ImportBoxVariantModal />
      <CustomizeBoxButton />

      <section className="col-span-2 grid gap-6 mt-4">
        <Link href="/" className="text-indigo-500 underline">
          {/* Dashboard */}
          Home
        </Link>
        <Link href="/deliveries" className="text-indigo-500 underline">
          Deliveries
        </Link>
        {/* TODO uncomment when we'll fix it */}
        {/* <Link href="/orders" className="text-indigo-500 underline">
          Orders
        </Link> */}
        {/* <Link href="/cities" className="text-indigo-500 underline">
          Cities
        </Link> */}
        <Link href="/subscriptions" className="text-indigo-500 underline">
          Subscriptions
        </Link>
        <Link href="/products" className="text-indigo-500 underline">
          Products
        </Link>
        <Link href="/ingredients" className="text-indigo-500 underline">
          Ingredients
        </Link>
        <Link
          external
          href="https://wwlabs.retool.com/apps/Limits%20v2"
          className="text-indigo-500 underline"
        >
          Limits
        </Link>
        {/* <Link
          external
          href="https://wwlabs.retool.com/apps/Edit%20countries"
          className="text-indigo-500 underline"
        >
          Countries
        </Link>
        <Link
          external
          href="https://wwlabs.retool.com/apps/Edit%20coupons"
          className="text-indigo-500 underline"
        >
          Coupons
        </Link> */}
        <Link external href="/inventory" className="text-indigo-500 underline">
          Stany
        </Link>
        {/* <Link external href="/users" className="text-indigo-500 underline">
          Users
        </Link> */}
        {/* <Link
          href="/deliveries/uploadBatch"
          className="text-indigo-500 underline"
        >
          Upload batch
        </Link> */}

        {/* <Link href="/analytics" className="text-indigo-500 underline">
          Analytics
        </Link> */}
      </section>
    </div>
  );
};
